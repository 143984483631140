export default {
  'pages.layouts.userLayout.title':
    "Шоргоолжны дизайн бол Шиху дүүргийн хамгийн нөлөө бүхий вэб дизайны үзүүлэлт",
  'pages.login.accountLogin.tab': 'Бүртгэлд нэвтрэх',
  'pages.login.accountLogin.errorMessage': 'Нэвтрэх нэр, Нууц үг буруу!',
  'pages.login.failure': 'Амжилтгүй, Дахин оролдоно уу!',
  'pages.login.success': 'Амжилттай нэвтэрлээ!',
  'pages.login.username.placeholder': 'Нэвтрэх нэрээ оруулах уу?',
  'pages.login.username.required': 'Нэвтрэх нэрээ оруулна уу!',
  'pages.login.email.placeholder': 'Цахим хаягаа оруулна уу?',
  'pages.login.email.required': 'Цахим хаягаа оруулна уу!',
  'pages.login.password.placeholder': 'Нууц үгээ оруулна уу?',
  'pages.login.password.required': 'Нууц үгээ оруулна уу!',
  'pages.login.phoneLogin.tab': 'Утсаар нэвтрэх',
  'pages.login.phoneLogin.errorMessage': 'Баталгаажуулах кодын алдаа',
  'pages.login.phoneNumber.placeholder': 'Утасны дугаар',
  'pages.login.phoneNumber.required': 'Утасны дугаараа оруулна уу!',
  'pages.login.phoneNumber.invalid': 'Утасны дугаар буруу байна!',
  'pages.login.captcha.placeholder': 'Баталгаажуулах код',
  'pages.login.captcha.required': 'Баталгаажуулах кодыг оруулна уу!',
  'pages.login.phoneLogin.getVerificationCode': 'Код авах',
  'pages.getCaptchaSecondText': 'сек(үүд)',
  'pages.login.rememberMe': 'Намайг санаарай',
  'pages.login.forgotPassword': 'Нууц үгээ мартсан уу?',
  'pages.login.submit': 'Нэвтрэх',
  'pages.login.loginWith': ':-ээр нэвтэрнэ үү',
  'pages.login.registerAccount': 'Бүртгүүлэх бүртгэл',
  'pages.welcome.advancedComponent': 'Дэвшилтэт бүрэлдэхүүн хэсэг',
  'pages.welcome.link': 'Тавтай морил',
  'pages.welcome.advancedLayout': 'Нарийвчилсан байршил',
  'pages.welcome.alertMessage': 'Илүү хурдан бөгөөд илүү хүчтэй хүнд даацын бүрэлдэхүүн хэсгүүд гарсан.',
  'pages.admin.subPage.title': 'Энэ хуудсыг зөвхөн админ үзэх боломжтой',
  'pages.admin.subPage.alertMessage': 'Umi ui одоо гарлаа, туршлагыг эхлүүлэхийн тулд npm run ui ашиглахыг урьж байна.',
  'pages.searchTable.createForm.newRule': 'Нэхэмжлэл үүсгэх',
  'pages.searchTable.updateForm.ruleConfig': 'Дүрмийн тохиргоо',
  'pages.searchTable.updateForm.basicConfig': 'Үндсэн мэдээлэл',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Дүрмийн нэр',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Дүрмийн нэрийг оруулна уу!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Дүрмийн тайлбар',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Хамгийн багадаа таван тэмдэгт оруулна уу',
  'pages.searchTable.updateForm.ruleDesc.descRules': 'Дор хаяж таван тэмдэгтээс бүрдэх дүрмийн тайлбарыг оруулна уу!',
  'pages.searchTable.updateForm.ruleProps.title': 'Properties тохируулах',
  'pages.searchTable.updateForm.object': 'Хяналтын объект',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Дүрмийн загвар',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Дүрмийн төрөл',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Хуваарийн хугацааг тохируулах',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Эхлэх цаг',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Эхлэх цагаа сонгоно уу!',
  'pages.searchTable.titleDesc': 'Тодорхойлолт',
  'pages.searchTable.ruleName': 'Дүрмийн нэр шаардлагатай',
  'pages.searchTable.titleCallNo': 'Үйлчилгээний дуудлагын тоо',
  'pages.searchTable.titleStatus': 'Статус',
  'pages.searchTable.nameStatus.default': 'анхдагч',
  'pages.searchTable.nameStatus.running': 'ажиллаж байна',
  'pages.searchTable.nameStatus.online': 'онлайн',
  'pages.searchTable.nameStatus.abnormal': 'хэвийн бус',
  'pages.searchTable.titleUpdatedAt': 'Хамгийн сүүлд товлогдсон',
  'pages.searchTable.exception': 'Үл хамаарах шалтгааныг оруулна уу!',
  'pages.searchTable.titleOption': 'Сонголт',
  'pages.searchTable.config': 'Тохиргоо',
  'pages.searchTable.subscribeAlert': 'Сэрэмжлүүлэгт бүртгүүлэх',
  'pages.searchTable.title': 'Лавлагаа',
  'pages.searchTable.new': 'Нэхэмжлэх үүсгэх',
  'pages.searchTable.chosen': 'сонгосон',
  'pages.searchTable.item': 'зүйл',
  'pages.searchTable.totalServiceCalls': 'Үйлчилгээний дуудлагын нийт тоо',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'багц устгах',
  'pages.searchTable.batchApproval': 'багцын зөвшөөрөл',
  'pages.forgotPassword.captcha.placeholder': 'Нийлбэрийг оруулах уу?',
  'pages.forgotPassword.captcha.required': 'Нийлбэрийг оруулна уу!',
  'pages.forgotPassword.email.placeholder': 'Цахим хаягаа оруулна уу?',
  'pages.forgotPassword.email.required': 'Цахим хаягаа оруулна уу!',
  'pages.form.phone': 'Утасны дугаар оруулах!',
  'pages.form.amount': 'Мөнгөн дүн оруулах!',
  'pages.form.description': 'Тайлбар оруулах!',
  'pages.form.externalId': 'Гадаад ID оруулах шаардлагатай!',
  'pages.form.expiredDate': 'Дуусах огноо оруулах!',
}
