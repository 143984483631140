import { extend } from 'umi-request';
import { history } from 'umi';
import { notification } from 'antd';
import { countBy } from 'lodash';

const errorHandler = function (error: any) {
  if (error.response) {
    // that falls out of the range of 2xx
    if (error.response.status == 401) {
      history.push('/user/login');
    }
  }

  throw error; // If throw. The error will continue to be thrown.
};
// 1. Unified processing
const extendRequest = extend({
  errorHandler,
});

const requestHandler = async (path: string, requestBody: any) => {
  return <API.ResponseApi>extendRequest(path, requestBody).then((data) => {
    return data;
  });
};

export const request = async (path: any, requestBody: any) => {
  requestBody.headers = { 'Content-Type': 'application/json', ...requestBody.headers };
  return requestHandler(path, requestBody)
    .then((data) => {
      if (typeof data.code == 'undefined') {
        return data;
      } else if (data.code === 0) {
        return data.response;
      } else {
        notification.error({
          message: 'Алдаа гарлаа',
          description: data.response,
        });
        throw data.response;
      }
    })
    .catch((e) => {
      throw e;
    });
};
export const fetchList = async (path: any, requestBody: any) => {
  requestBody.headers = { ...requestBody.headers, 'Content-Type': 'application/ld+json' };
  return request(path, requestBody).then((data) => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
      groupCount: 10,
    };
  });
};
export const fetchforProTable = async (path: any, requestBody: any) => {
  requestBody.headers = { ...requestBody.headers, 'Content-Type': 'application/ld+json' };
  return request(path, requestBody).then((data) => {
    if (data.result) {
      return {
        data: data.result,
        total: data.totalItems,
        success: true,
      };
    } else {
      return {
        data: data['hydra:member'],
        total: data['hydra:totalItems'],
        success: true,
      };
    }
  });
};
export const fetchOne = async (path: any, requestBody: any) => {
  requestBody.headers = { ...requestBody.headers, 'Content-Type': 'application/ld+json' };
  return request(path, requestBody).then((data) => {
    return data;
  });
};

export const fetchListWithOutPage = async (path: any, requestBody: any) => {
  requestBody.headers = { ...requestBody.headers, 'Content-Type': 'application/ld+json' };
  return request(path, requestBody).then((data) => {
    return {
      data: data,
      totalCount: countBy(data),
      groupCount: 10,
    };
  });
};
