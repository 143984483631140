export default {
  'transaction.walletNumber': 'Хэтэвчний дугаар',
  'transaction.code': 'Код',
  'transaction.description': 'Тайлбар',
  'transaction.status': 'Төлөв',
  'transaction.type': 'Төрөл',
  'transaction.invoiceNumber': 'Зээлийн дугаар',
  'transaction.amount': 'Дүн',
  'transaction.amountType': 'Гүйлгээний төрөл',
  'transaction.firstName': 'Нэр',
  'transaction.phoneNumber': 'Утас',
  'transaction.trackingData': 'Мэдээлэл',
  'transaction.date': 'огноо',
  'transaction.startDate': 'Эхлэх огноо',
  'transaction.endDate': 'Дуусах огноо',
};
