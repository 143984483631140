import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import { currentUser as queryCurrentUser } from './services/user/api';
import request from 'umi-request';
const isDev = process.env.NODE_ENV === 'development';

const loginPaths = ['/user/login', '/user/ForgotPassword'];

export const initialStateConfig = {
  loading: <PageLoading />,
};
request.interceptors.request.use((url, options) => {
  let baseUrl = process.env.UMI_APP_URL;
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    ...options.headers,
  };

  if(url.includes('identity')) {
    baseUrl = process.env.UMI_APP_URL_IDENTITY;
    url = url.replace('/identity', '');
  }

  return {
    url: `${baseUrl}${url}`,
    options: { ...options, interceptors: true, headers },
  };
});
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg;
    } catch (error) {
      history.push(loginPaths[0]);
    }
    return undefined;
  };
  if (!loginPaths.includes(history.location.pathname)) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
    };
  }
  return {
    fetchUserInfo,
    settings: {},
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    onPageChange: () => {
      const { location } = history;
      if (!initialState?.currentUser && !loginPaths.includes(location.pathname)) {
        history.push(loginPaths[0]);
      }
    },
    links: isDev ? [] : [],
    menuHeaderRender: undefined,
    ...initialState?.settings,
  };
};
