export default {
  'invoice.detail.title': 'Нэхэмжлэхийн дэлгэрэнгүй',
  'invoice.detail': 'Дэлгэрэнгүй',
  'invoice.invoiceNumber': 'Нэхэмжлэхийн дугаар',
  'invoice.invoiceNumber.short': 'Нэхэмжлэх',
  'invoice.status': 'Төлөв',
  'invoice.amount': 'Дүн',
  'invoice.paidAmount': 'Төлөгдсөн дүн',
  'invoice.holdAmount': 'Хүлээгдэж буй дүн',
  'invoice.feeAmount': 'Шимтгэл дүн',
  'invoice.transactionType': 'Гүйлгээний төрөл',
  'invoice.description': 'Тайлбар',
  'invoice.expiredDate': 'Дуусах огноо',
  'invoice.completedDate': 'Төлсөн огноо',
  'invoice.createdDate': 'Үүссэн огноо',
  'invoice.startDate': 'Эхлэх огноо',
  'invoice.endDate': 'Дуусах огноо',
  'invoice.phoneNumber': 'Утасны дугаар',
  'invoice.externalId': 'Гадаад ID',
  'invoice.createdDate.short': 'Огноо',
  'form.invoice.amount': 'Мөнгөн дүн',
  'invoice.totalAmount': 'Нийт дүн',
  'invoice.totalFeeAmount': 'Нийт шимтгэл дүн',
  'invoice.totalPaidAmount': 'Нийт төлөгдсөн дүн',
  'invoice.totalHoldAmount': 'Нийт хүлээгдэж буй дүн',
};
