// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { request as coreRequest } from '@/services/api/request';

export async function currentUser(options?: { [key: string]: any }) {
  return coreRequest('/api/jw/merchant/user/info', {
    method: 'GET',
    ...(options || {}),
  });
}

export function outLogin(accessToken?: string | null, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/identity/api/logout', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      deviceUuid: window.navigator.userAgent,
    },
    ...(options || {}),
  });
}

export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  let requestBody = {
    identity: body.username,
    password: body.password,
    uuid: Buffer.from(window.navigator.userAgent).toString('base64'),
  };
  return coreRequest('/identity/api/user/login_check', {
    method: 'POST',
    data: requestBody,
    ...(options || {}),
  });
}
