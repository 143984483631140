export default {
  'transaction.walletNumber': 'Wallet number',
  'transaction.code': 'Code',
  'transaction.description': 'Description',
  'transaction.status': 'Status',
  'transaction.type': 'Type',
  'transaction.invoiceNumber': 'Invoice number',
  'transaction.amount': 'Amount',
  'transaction.amountType': 'Amount type',
  'transaction.firstName': 'Firstname',
  'transaction.phoneNumber': 'Phone',
  'transaction.trackingData': 'Tracking data',
  'transaction.date': 'Date',
  'transaction.startDate': 'Start date',
  'transaction.endDate': 'End date',
};
