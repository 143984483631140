export default {
  'invoice.detail.title': 'Invoice detail',
  'invoice.detail': 'Details',
  'invoice.invoiceNumber': 'Invoice number',
  'invoice.invoiceNumber.short': 'Invoice',
  'invoice.status': 'Status',
  'invoice.amount': 'Amount',
  'invoice.paidAmount': 'Paid amount',
  'invoice.holdAmount': 'Hold amount',
  'invoice.feeAmount': 'Fee amount',
  'invoice.transactionType': 'Transaction type',
  'invoice.description': 'Description',
  'invoice.expiredDate': 'Expired date',
  'invoice.completedDate': 'Completed date',
  'invoice.createdDate': 'Created date',
  'invoice.startDate': 'Start date',
  'invoice.endDate': 'End date',
  'invoice.phoneNumber': 'Phone',
  'invoice.externalId': 'External ID',
  'invoice.createdDate.short': 'Date',
  'form.invoice.amount': 'Amount',
  'invoice.totalAmount': 'Total amount',
  'invoice.totalFeeAmount': 'Total fee amount',
  'invoice.totalPaidAmount': 'Total paid amount',
  'invoice.totalHoldAmount': 'Total hold amount',
};
