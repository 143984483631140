/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  return {
    invoiceList: currentUser && currentUser?.userRoles?.includes('GET:/api/jw/payment/invoices'),
    invoiceEdit:
      currentUser && currentUser?.userRoles?.includes('POST:/api/jw/payment/create-invoice'),
    walletTransactions:
      currentUser && currentUser?.userRoles?.includes('GET:/api/jw/w/transactions'),
    invoiceSummary:
      currentUser && currentUser?.userRoles?.includes('GET:/api/jw/payment/invoices/summary'),
    transcationSummary:
      currentUser && currentUser?.userRoles?.includes('GET:/api/jw/w/transactions/summary'),
  };
}
