export default {
  'wallet.walletNumber': 'Wallet number',
  'wallet.beforeBalance': 'First balance',
  'wallet.balance': 'Last balance',
  'wallet.incomeAmount': 'Income',
  'wallet.expenseAmount': 'Expense',
  'wallet.date': 'date',
  'wallet.startDate': 'Start date',
  'wallet.endDate': 'End date',
  'wallet.sub': 'sub wallet',
  'wallet.primary': 'primary wallet',
};
