export default {
  'wallet.walletNumber': 'Хэтэвчний дугаар',
  'wallet.beforeBalance': 'Эхний үлдэгдэл',
  'wallet.balance': 'Эцсийн үлдэгдэл',
  'wallet.incomeAmount': 'Орлого',
  'wallet.expenseAmount': 'Зарлага',
  'wallet.date': 'огноо',
  'wallet.startDate': 'Эхлэх огноо',
  'wallet.endDate': 'Дуусах огноо',
  'wallet.sub': 'Түр хэтэвч',
  'wallet.primary': 'Үндсэн хэтэвч',
};
