export default {
  'component.globalHeader.search': 'Search',
  'component.globalHeader.search.example1': 'Search example 1',
  'component.globalHeader.search.example2': 'Search example 2',
  'component.globalHeader.search.example3': 'Search example 3',
  'component.globalHeader.help': 'Help',
  'component.globalHeader.notification': 'Notification',
  'component.globalHeader.notification.empty': 'You have viewed all notifications.',
  'component.globalHeader.message': 'Message',
  'component.globalHeader.message.empty': 'You have viewed all messsages.',
  'component.globalHeader.event': 'Event',
  'component.globalHeader.event.empty': 'You have viewed all events.',
  'component.noticeIcon.clear': 'Clear',
  'component.noticeIcon.cleared': 'Cleared',
  'component.noticeIcon.empty': 'No notifications',
  'component.noticeIcon.view-more': 'View more',
  'add.success': 'Амжилттай хадгаллаа',
  'error.occurred': 'Хүсэлт амжилтгүй',
  'table.column.name': 'Нэр',
  'table.column.status': 'Төлөв',
  'table.column.type': 'Төрөл',
  'table.column.action': 'Үйлдэл',
  'table.column.gender': 'Хүйс',
  'table.column.createdAt': 'Үүсгэсэн огноо',
  'table.column.updatedAt': 'Зассан огноо',
  'form.valid.isRequired': ' талбарыг бөглөнө үү',
  'form.select.placeholder': 'Сонгох',
  'form.field.placeholder': 'Мэдээллээ оруулна уу...',
  'form.add': 'Нэмэх',
  'form.edit': 'Засах',
  'form.previous': 'Өмнөх',
  'form.next': 'Дараах',
  'form.finish': 'Дуусгах',
  'form.search': 'Хайх',
  'form.reset': 'Цэвэрлэх',
  'form.loading': 'Уншиж байна...',
  'form.result.title': 'Мэдээллээ баталгаажуулна уу',
  'stepsForm.next': 'Дараах',
};
