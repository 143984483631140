import globalHeader from './mn-MN/globalHeader';
import menu from './mn-MN/menu';
import settingDrawer from './mn-MN/settingDrawer';
import settings from './mn-MN/settings';
import pwa from './mn-MN/pwa';
import component from './mn-MN/component';
import pages from './mn-MN/pages';
import invoice from './mn-MN/invoice';
import transaction from '@/locales/mn-MN/transaction';
import wallet from '@/locales/mn-MN/wallet';

export default {
  'navBar.lang': '言語',
  'layout.user.link.help': 'ヘルプ',
  'layout.user.link.privacy': 'プライバシー',
  'layout.user.link.terms': '利用規約',
  'app.preview.down.block': 'このページをローカルプロジェクトにダウンロードしてください',
  'app.welcome.link.fetch-blocks': '',
  'app.welcome.link.block-list': '',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...invoice,
  ...transaction,
  ...wallet,
};
