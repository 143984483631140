import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '',
  pwa: false,
  logo: '/Logo-white.png',
  iconfontUrl: '',
};

export const Configs: object & {
  merchant?: string;
  idService?: string;
  testBaseUrl?: string;
  preBaseUrl?: string;
} = {
  merchant: 'https://merchant-url',
  // idService: 'http://10.0.7.85:8080',
  idService: 'http://identification-url',
  testBaseUrl: 'http://test-merchant-url',
  preBaseUrl: 'http://test-identification-url',
};

export default Settings;
