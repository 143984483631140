// @ts-nocheck

import ContainerOutlined from '@ant-design/icons/ContainerOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'

export default {
  ContainerOutlined,
SmileOutlined,
CloseCircleOutlined
}
    